import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Accordion01 from "../elements/Accordion";
import ContactTwo from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck, FiCloud, FiSmile } from "react-icons/fi";
import { GiFactoryArm } from "react-icons/gi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";

const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--41',
        category: '',
        title: 'AI.',
        description: 'Intelligent insights, seamless automation.',
        buttonText: 'Contact Us',
        buttonLink: '/#contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--44',
        category: '',
        title: 'IoT.',
        description: 'Industrial IoT and Big Data for your Digitalization journey.',
        buttonText: 'Contact Us',
        buttonLink: '/#contact'
    },
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--43',
        category: '',
        title: 'Cloud.',
        description: 'Cloud-based solutions for scalability and reliability.',
        buttonText: 'Contact Us',
        buttonLink: '/#contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--42',
        category: '',
        title: 'Software.',
        description: 'Robust, scalable and innovative software solutions.',
        buttonText: 'Contact Us',
        buttonLink: '/#contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--45',
        category: '',
        title: 'Team.',
        description: 'We know how to keep the team on track to achieve goals.',
        buttonText: 'Contact Us',
        buttonLink: '/#contact'
    },
]

const ServiceListOne = [
    { 
        icon: <GiFactoryArm />,
        title: 'Industrial Digitalization',
        description: 'Innovate processes and products using Artificial Intelligence, IoT, Big Data and Immersive technologies. Explore our Cloud-native solutions for Asset Monitoring and Maintenance.'
    },
    {
        icon: <FiCloud />,
        title: 'Cloud Engineering',
        description: 'Unlock the power of the Cloud with our experts, offering end-to-end solutions for your business scalability, including MLOps, DevSecOps and SRE best practices for Public Cloud Providers like AWS, GCP, and Azure.'
    },
    {
        icon: <FiLayers />,
        title: 'Software Development',
        description: 'Get modern software solutions and development support delivered by our network of senior engineers, using agile methodologies to achieve remarkable results.'
    },
    {
        icon: <FiUsers />,
        title: 'Team Management',
        description: 'Use our expertise to enhance communication, optimize workflow, empower your teams to deliver exceptional results, drive innovation, and achieve unparalleled project success.'
    },
]

const starndardService = [
    {
        image: '01',
        title: 'Thinking Development',
        description: 'I throw myself down among the tall grass by the stream',
    },
    {
        image: '02',
        title: 'Business Consulting',
        description: 'I throw myself down among the tall grass by the stream',
    },
    {
        image: '03',
        title: 'Biseness Development',
        description: 'I throw myself down among the tall grass by the stream',
    },

]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'Software and Cloud-native solutions',
            'Network of experienced professionals',
            'Long-term partnerships',
            'Outsourcing for startups and large companies',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="TechnoSec - Digital Technologies and Consulting Services" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1" id="services">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5" id="about">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">Discover how TechnoSec can transform your business with our comprehensive software and cloud consulting services. Contact us today to start your journey towards digital transformation.</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>What we offer</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    {/* <div className="mt--30"> */}
                                    {/*     <h4>Lorem ipsum dolor sit.</h4> */}
                                    {/*     <ul className="list-style--1"> */}
                                    {/*         {namesItemTwo.map((name, index) => { */}
                                    {/*             return <li key={ index }><FiCheck /> {name}</li>; */}
                                    {/*         })} */}
                                    {/*     </ul> */}
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images"/>
                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} /> */}
                                    {/* <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                {/* Start Counterup Area */}
                {/* <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7"> */}
                {/*     <div className="container"> */}
                {/*         <CounterOne /> */}
                {/*     </div> */}
                {/* </div> */}
                {/* End Counterup Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Working Process</h2>
                                        <p>Our workflow in four stages.</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div>
                                    {/* <div className="about-button mt--50"> */}
                                    {/*     <a className="rn-button-style--2 btn-solid" href="/about">See how it works</a> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--5" id="contact">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;
